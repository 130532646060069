import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {faPhone, faMailBulk, faLocationArrow, faClock } from '@fortawesome/free-solid-svg-icons'
import Logo from './Logo'

const Footer = () => {
    const [isActive, setActive] = useState(false);
  // const emailRef = useRef(null);
  // const [modal, setModal] = useState(false);

  // const Toggle = (e) => {
  //   e.preventDefault();
  //   setModal(!modal);
  // }
  const scrollTo = (id) => {
    let element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
    setActive(!isActive)
  }

  return (
    <section className="footer">
      <div className="foot-container">
      <div className="foot">
        <Logo />
        <p>A leading law firm providing professional service in the field of law</p>
        <ul>
          <li>
            <a href="www.facebook.com" target='_blank' rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li>
          <a href="www.facebook.com" target='_blank' rel="noreferrer">
          <FontAwesomeIcon icon={faFacebook}/>
          </a>
          </li>
          <li>
          <a href="www.facebook.com" target='_blank' rel="noreferrer">
          <FontAwesomeIcon icon={faTwitter}/>
            </a>
          </li>
        </ul>
      </div>
      <div className="foot2">
        <h4>useful links</h4>
        <ul>
          <li onClick={() => scrollTo('work')}>
            <a href="#about">
            about us
            </a>
          </li>
          {/* <li>
            <a href="/">case style 01</a>
          </li>
          <li>
            <a href="/">contact us</a>
          </li>
          <li>
            <a href="/">corporate law</a>
          </li>
          <li>
            <a href="/">real estate law</a>
          </li> */}
        </ul>
      </div>
      <div className="foot3">
        <h4>office info</h4>
        <ul>
          <li>
              <FontAwesomeIcon icon={faPhone} />
              <p>
              +92-946-723356
              </p>
              <p>
              +92 300 5746744
              </p>
              <p>
              +92-342-9611335
              </p>
          </li>
          <li>
              <FontAwesomeIcon icon={faMailBulk} />
              <p>sslawswat@gmail.com</p>
          </li>
          <li>
              <FontAwesomeIcon icon={faLocationArrow} />
              <p>S-8-9, Second Floor, Continental Plaza, Makanbagh, Mingora, Swat, KP, Pakistan</p>
          </li>
          <li>
              <FontAwesomeIcon icon={faClock} />
              <p>
                Mon to Fri 
                9.00 am to 6.00am
              </p>
          </li>
        </ul>
      </div>
      {/* <div className="foot4"> */}
        {/* <h4>
          subscribe now
        </h4>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
        <form action="" onSubmit={Toggle}>
          <div>
            <input type="email" name="email" id='email-foot' placeholder='your email address' ref={emailRef} required/>
            <Button title='subscribe now' />
            {
              modal && <Modal show={modal} />
            }
          </div>
        </form> */}
      {/* </div> */}
      </div>
      <p id='copy'>&copy; 2024 - 2025 shahandshalaw.com</ p>
    </section>
  )
}

export default Footer