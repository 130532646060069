import React from 'react'
// import Nbp from '../../subassets/nbp.jpg'
// import Nha from '../../subassets/nha.jpg'
// import Sngpl from '../../subassets/SNGPL.png'
// import tma from '../../subassets/tma.jpeg'
// import bise from '../../subassets/bise.png'
import { Spin } from 'antd'

const Trust = ({companies, loading}) => {
  return (
    <section id='trust' className='trust'>
      <h4 className="tophead">Trusted companies</h4>
      <p>Our law firm representing these companies in various legal issues and matters in the High Court and special courts as well.</p>
      <div className="companies-container">
        {(companies.length === 0 && loading) && <Spin size="large" />}
        {
          companies.length > 0 && companies.map((company, index) => (
            <div className="company" key={company.key}>
              <img src={company.image} alt={company.name} style={{ height: '120px', width: '120px' }} />
              <h6>{company.name}</h6>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default Trust